// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.modal_container__SmpB6 {
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1000;
}

.modal_backdrop__0Bn65 {
    position: fixed;
    background-color: #000000;
    opacity: 16%;
    width: 100%;
    height: 100%;
}

.modal_modal__nSuwB {
    width: 36%;
    min-width: 400px;
    background-color: #FFFFFF;
    border-radius: 16px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    box-shadow: 0 4px 24px 0 #00000040;
}

.modal_header__SBslo {
    padding: 0 30px;
    display: flex;
    height: 60px;
    overflow-x: hidden;
    background-color: var(--main-color);
    border-radius: 16px 16px 0 0;
    color: #FFFFFF;
    font-weight: 600;
    font-size: 24px;
    line-height: 28px;
    align-items: center;
}

.modal_divider__9J7oY {
    margin: 0 10px;
    width: 1px;
    background-color: #E2E5F4;
}

.modal_desc__kq0Hk {
    border-radius: 7px;
    background-color: #E2E5F4;
    color: #5863B4;
    line-height: 21px;
    padding: 3px 6px;
}

.modal_title__GtnE\\+ {
    min-width: 250px;
    text-align: left;
    border-radius: 16px 0 0 0;
    padding: 16px 26px;
    white-space: nowrap;
}`, "",{"version":3,"sources":["webpack://./src/components/ajonjolib/modal/modal/modal.module.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,YAAY;IACZ,eAAe;IACf,MAAM;IACN,OAAO;IACP,aAAa;AACjB;;AAEA;IACI,eAAe;IACf,yBAAyB;IACzB,YAAY;IACZ,WAAW;IACX,YAAY;AAChB;;AAEA;IACI,UAAU;IACV,gBAAgB;IAChB,yBAAyB;IACzB,mBAAmB;IACnB,kBAAkB;IAClB,QAAQ;IACR,SAAS;IACT,gCAAgC;IAChC,kCAAkC;AACtC;;AAEA;IACI,eAAe;IACf,aAAa;IACb,YAAY;IACZ,kBAAkB;IAClB,mCAAmC;IACnC,4BAA4B;IAC5B,cAAc;IACd,gBAAgB;IAChB,eAAe;IACf,iBAAiB;IACjB,mBAAmB;AACvB;;AAEA;IACI,cAAc;IACd,UAAU;IACV,yBAAyB;AAC7B;;AAEA;IACI,kBAAkB;IAClB,yBAAyB;IACzB,cAAc;IACd,iBAAiB;IACjB,gBAAgB;AACpB;;AAEA;IACI,gBAAgB;IAChB,gBAAgB;IAChB,yBAAyB;IACzB,kBAAkB;IAClB,mBAAmB;AACvB","sourcesContent":[".container {\n    width: 100%;\n    height: 100%;\n    position: fixed;\n    top: 0;\n    left: 0;\n    z-index: 1000;\n}\n\n.backdrop {\n    position: fixed;\n    background-color: #000000;\n    opacity: 16%;\n    width: 100%;\n    height: 100%;\n}\n\n.modal {\n    width: 36%;\n    min-width: 400px;\n    background-color: #FFFFFF;\n    border-radius: 16px;\n    position: absolute;\n    top: 50%;\n    left: 50%;\n    transform: translate(-50%, -50%);\n    box-shadow: 0 4px 24px 0 #00000040;\n}\n\n.header {\n    padding: 0 30px;\n    display: flex;\n    height: 60px;\n    overflow-x: hidden;\n    background-color: var(--main-color);\n    border-radius: 16px 16px 0 0;\n    color: #FFFFFF;\n    font-weight: 600;\n    font-size: 24px;\n    line-height: 28px;\n    align-items: center;\n}\n\n.divider {\n    margin: 0 10px;\n    width: 1px;\n    background-color: #E2E5F4;\n}\n\n.desc {\n    border-radius: 7px;\n    background-color: #E2E5F4;\n    color: #5863B4;\n    line-height: 21px;\n    padding: 3px 6px;\n}\n\n.title {\n    min-width: 250px;\n    text-align: left;\n    border-radius: 16px 0 0 0;\n    padding: 16px 26px;\n    white-space: nowrap;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `modal_container__SmpB6`,
	"backdrop": `modal_backdrop__0Bn65`,
	"modal": `modal_modal__nSuwB`,
	"header": `modal_header__SBslo`,
	"divider": `modal_divider__9J7oY`,
	"desc": `modal_desc__kq0Hk`,
	"title": `modal_title__GtnE+`
};
export default ___CSS_LOADER_EXPORT___;
