// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles_container__m1VJB {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    -webkit-user-select: none;
            user-select: none;
}

.styles_button__gQJ\\+3 {
    display: flex;
}

.styles_button__gQJ\\+3>div {
    background-color: #2c2c7a;
    color: white;
    font-size: 24px;
    border: none;
    padding: 14px 16px;
    border-radius: 8px;
    cursor: pointer;
}`, "",{"version":3,"sources":["webpack://./src/components/project/creation/styles.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,uBAAuB;IACvB,sBAAsB;IACtB,mBAAmB;IACnB,yBAAiB;YAAjB,iBAAiB;AACrB;;AAEA;IACI,aAAa;AACjB;;AAEA;IACI,yBAAyB;IACzB,YAAY;IACZ,eAAe;IACf,YAAY;IACZ,kBAAkB;IAClB,kBAAkB;IAClB,eAAe;AACnB","sourcesContent":[".container {\n    display: flex;\n    justify-content: center;\n    flex-direction: column;\n    align-items: center;\n    user-select: none;\n}\n\n.button {\n    display: flex;\n}\n\n.button>div {\n    background-color: #2c2c7a;\n    color: white;\n    font-size: 24px;\n    border: none;\n    padding: 14px 16px;\n    border-radius: 8px;\n    cursor: pointer;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `styles_container__m1VJB`,
	"button": `styles_button__gQJ+3`
};
export default ___CSS_LOADER_EXPORT___;
