// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles_container__tMpU7 {
    background-color: var(--main-color);
    height: 80px;
    color: #FFF;
    display: flex;
    padding: 0 20px;
    align-items: center;
    font-size: 24px;
}`, "",{"version":3,"sources":["webpack://./src/components/header/styles.module.css"],"names":[],"mappings":"AAAA;IACI,mCAAmC;IACnC,YAAY;IACZ,WAAW;IACX,aAAa;IACb,eAAe;IACf,mBAAmB;IACnB,eAAe;AACnB","sourcesContent":[".container {\n    background-color: var(--main-color);\n    height: 80px;\n    color: #FFF;\n    display: flex;\n    padding: 0 20px;\n    align-items: center;\n    font-size: 24px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `styles_container__tMpU7`
};
export default ___CSS_LOADER_EXPORT___;
