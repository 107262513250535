// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles_container__BwROT {
    height: 100%;
    display: flex;
}

.styles_container__BwROT div {
    height: 100%;
    align-items: start;
    display: flex;
}

.styles_container__BwROT input {
    border: none;
    outline: none;
    height: 100%;
}

.styles_alertPill__fS6LG {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.styles_alertPill__fS6LG div {
    margin: 0 10px 0 3px;
    max-height: 90%;
    width: 4px;
    border-radius: 4px;
}`, "",{"version":3,"sources":["webpack://./src/components/contents/table_input/styles.module.css"],"names":[],"mappings":"AAAA;IACI,YAAY;IACZ,aAAa;AACjB;;AAEA;IACI,YAAY;IACZ,kBAAkB;IAClB,aAAa;AACjB;;AAEA;IACI,YAAY;IACZ,aAAa;IACb,YAAY;AAChB;;AAEA;IACI,aAAa;IACb,sBAAsB;IACtB,uBAAuB;IACvB,mBAAmB;AACvB;;AAEA;IACI,oBAAoB;IACpB,eAAe;IACf,UAAU;IACV,kBAAkB;AACtB","sourcesContent":[".container {\n    height: 100%;\n    display: flex;\n}\n\n.container div {\n    height: 100%;\n    align-items: start;\n    display: flex;\n}\n\n.container input {\n    border: none;\n    outline: none;\n    height: 100%;\n}\n\n.alertPill {\n    display: flex;\n    flex-direction: column;\n    justify-content: center;\n    align-items: center;\n}\n\n.alertPill div {\n    margin: 0 10px 0 3px;\n    max-height: 90%;\n    width: 4px;\n    border-radius: 4px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `styles_container__BwROT`,
	"alertPill": `styles_alertPill__fS6LG`
};
export default ___CSS_LOADER_EXPORT___;
